// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  country: {
    root: path(ROOTS_DASHBOARD, '/country/list'),
    list: path(ROOTS_DASHBOARD, '/country/list'),
    new: path(ROOTS_DASHBOARD, '/country/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/country/${id}/edit`)
  },
  port: {
    root: path(ROOTS_DASHBOARD, '/port/list'),
    list: path(ROOTS_DASHBOARD, '/port/list'),
    new: path(ROOTS_DASHBOARD, '/port/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/port/${id}/edit`)
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company/list'),
    list: path(ROOTS_DASHBOARD, '/company/list'),
    new: path(ROOTS_DASHBOARD, '/company/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/company/${id}/edit`)
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer/list'),
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    new: path(ROOTS_DASHBOARD, '/customer/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/customer/${id}/edit`)
  },
  seaFreight: {
    root: path(ROOTS_DASHBOARD, '/seaFreight/list'),
    list: path(ROOTS_DASHBOARD, '/seaFreight/list'),
    new: path(ROOTS_DASHBOARD, '/seaFreight/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/seaFreight/${id}/edit`)
  },
  margin: {
    root: path(ROOTS_DASHBOARD, '/margin/list'),
    list: path(ROOTS_DASHBOARD, '/margin/list'),
    new: path(ROOTS_DASHBOARD, '/margin/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/margin/${id}/edit`)
  },
  quotation: {
    root: path(ROOTS_DASHBOARD, '/quotation'),
    list: path(ROOTS_DASHBOARD, '/quotation/list'),
    new: path(ROOTS_DASHBOARD, '/quotation/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/quotation/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/quotation/${id}/edit`)
  },
};
