import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'UPDATE') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'CHANGE_PASSWORD') {
    return {
      ...state,
      isAuthenticated: true
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async (user) => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      let userFromStorage = storageAvailable ? localStorage.getItem('user') : '';
      userFromStorage = JSON.parse(userFromStorage)

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(`/api/users/getOneUser/${userFromStorage?.id}`);
        console.log(response)
        console.log(response.data)
        user  = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await axios.post('/api/users/login', {
      email,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    localStorage.setItem('user', JSON.stringify(user));

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/users/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT'
    });
  }, []);

  // UPDATE
  const update = useCallback(async (user) => {
    console.log(user)
    const response = await axios.put(`/api/users/updateUser/${user.id}`, {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone_number: user.phone_number,
      address: user.address,
      country: user.country,
      city: user.city,
      postal_code: user.postal_code
    });
    user = response.data;

    localStorage.setItem('user', JSON.stringify(user));

    dispatch({
      type: 'UPDATE',
      payload: {
        user,
      },
    });
  }, []);

  // CHANGE PASSWORD
  const changePassword = useCallback(async (user) => {
    console.log(user)
    const response = await axios.put(`/api/users/changePassword/${user.id}`, {
      oldPassword: user.oldPassword,
      newPassword: user.newPassword,
    });
    user = response.data;

    dispatch({
      type: 'CHANGE_PASSWORD'
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      update,
      changePassword
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, update, changePassword]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
