import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import countryReducer from './slices/country';
import portReducer from './slices/port';
import companyReducer from './slices/company';
import customerReducer from './slices/customer';
import seaFreightReducer from "./slices/seaFreight";
import marginReducer from "./slices/margin";
import quotationHeaderReducer from "./slices/quotationHeader";
import quotationReducer from "./slices/quotation";

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const countryPersistConfig = {
  key: 'country',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const portPersistConfig = {
  key: 'port',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const companyPersistConfig = {
  key: 'company',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const seaFreightPersistConfig = {
  key: 'seaFreight',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
export const marginPersistConfig = {
  key: 'margin',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
export const quotationHeaderPersistConfig = {
  key: 'quotationHeader',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const quotationPersistConfig = {
  key: 'quotation',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  country: persistReducer(countryPersistConfig, countryReducer),
  port: persistReducer(portPersistConfig, portReducer),
  company: persistReducer(companyPersistConfig, companyReducer),
  customer: persistReducer(customerPersistConfig, customerReducer),
  seaFreight: persistReducer(seaFreightPersistConfig, seaFreightReducer),
  margin: persistReducer(marginPersistConfig, marginReducer),
  quotationHeader: persistReducer(quotationHeaderPersistConfig, quotationHeaderReducer),
  quotation: persistReducer(quotationPersistConfig, quotationReducer),
});

export default rootReducer;
