import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  success: null,
  quotationHeaders: [],
  quotationHeader: null
};

const slice = createSlice({
  name: 'quotationHeader',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS SUCCESS
    hasSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET QUOTATION HEADERS
    getQuotationHeadersSuccess(state, action) {
      state.isLoading = false;
      state.quotationHeaders = action.payload;
    },

    // GET QUOTATION HEADER
    getQuotationHeaderSuccess(state, action) {
      state.isLoading = false;
      state.quotationHeader = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQuotationHeaders() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/quotationTemplates/getAllQuotationTemplates');
      dispatch(slice.actions.getQuotationHeaderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getQuotationHeader(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/quotationTemplates/getOneQuotationTemplate/${id}`);
      console.log(response)
      dispatch(slice.actions.getQuotationHeaderSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  }
}
