import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  success: null,
  companies: [],
  company: null
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS SUCCESS
    hasSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET COMPANIES
    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload;
    },

    // GET COMPANY
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCompanies() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/companies/getAllCompanies');
      dispatch(slice.actions.getCompaniesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompany(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/companies/getOneCompany/${id}`);
      console.log(response)
      dispatch(slice.actions.getCompanySuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  }
}
