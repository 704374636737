import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, Button, TextField } from "@mui/material";
import { useState } from "react";

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  helperText: PropTypes.node,
  multiple: PropTypes.bool
};

export default function RHFAutocomplete({ name, label,helperText, options, multiple,
                                          ...other }) {
  const { control, setValue } = useFormContext();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSelectAll = () => {
    setSelectedOptions(options);
    setValue(name, options);
  };

  const handleDeselectAll = () => {
    setSelectedOptions([]);
    setValue(name, []);
  };

  return (
    <>
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          getOptionLabel={(option)=> option?.name}
          options={options}
          multiple={multiple}
          selectedOptions={selectedOptions}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={(event, newValue) => {
            setValue(name, newValue);
            setSelectedOptions(newValue);
          }}
          renderInput={(params) => (
            <TextField
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          {...other}
        />
      )}
    />
      {
        multiple ?
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleSelectAll}>Select All</Button>
            <Button onClick={handleDeselectAll}>Deselect All</Button>
          </div>
          : null
      }
    </>
  );
}
