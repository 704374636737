import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  success: null,
  quotations: [],
  quotation: null
};

const slice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS SUCCESS
    hasSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET QUOTATIONS
    getQuotationsSuccess(state, action) {
      state.isLoading = false;
      state.quotations = action.payload;
    },

    // GET QUOTATION
    getQuotationSuccess(state, action) {
      state.isLoading = false;
      state.quotation = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQuotations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/quotations/getAllQuotations');
      dispatch(slice.actions.getQuotationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getQuotation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/quotations/getOneQuotation/${id}`);
      console.log(response)
      dispatch(slice.actions.getQuotationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  }
}
