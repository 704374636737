import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  LoginPage,
  // Dashboard: User
  UserAccountPage,
  // Dashboard: Country
  CountryListPage,
  CountryCreatePage,
  CountryEditPage,
  // Dashboard: Port
  PortListPage, PortEditPage, PortCreatePage,
  CompanyListPage, CompanyEditPage, CompanyCreatePage,
  CustomerListPage, CustomerEditPage, CustomerCreatePage,
  SeaFreightListPage, SeaFreightEditPage, SeaFreightCreatePage,
  MarginListPage, MarginEditPage, MarginCreatePage,
  QuotationListPage, QuotationDetailsPage, QuotationEditPage, QuotationCreatePage
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'country',
          children: [
            { element: <Navigate to="/dashboard/country" replace />, index: true },
            { path: 'list', element: <CountryListPage /> },
            { path: 'new', element: <CountryCreatePage /> },
            { path: ':id/edit', element: <CountryEditPage /> },
          ],
        },
        {
          path: 'port',
          children: [
            { element: <Navigate to="/dashboard/port" replace />, index: true },
            { path: 'list', element: <PortListPage /> },
            { path: 'new', element: <PortCreatePage /> },
            { path: ':id/edit', element: <PortEditPage /> },
          ],
        },
        {
          path: 'company',
          children: [
            { element: <Navigate to="/dashboard/company" replace />, index: true },
            { path: 'list', element: <CompanyListPage /> },
            { path: 'new', element: <CompanyCreatePage /> },
            { path: ':id/edit', element: <CompanyEditPage /> },
          ],
        },
        {
          path: 'customer',
          children: [
            { element: <Navigate to="/dashboard/customer" replace />, index: true },
            { path: 'list', element: <CustomerListPage /> },
            { path: 'new', element: <CustomerCreatePage /> },
            { path: ':id/edit', element: <CustomerEditPage /> },
          ],
        },
        {
          path: 'seaFreight',
          children: [
            { element: <Navigate to="/dashboard/seaFreight" replace />, index: true },
            { path: 'list', element: <SeaFreightListPage /> },
            { path: 'new', element: <SeaFreightCreatePage /> },
            { path: ':id/edit', element: <SeaFreightEditPage /> },
          ],
        },
        {
          path: 'margin',
          children: [
            { element: <Navigate to="/dashboard/margin" replace />, index: true },
            { path: 'list', element: <MarginListPage /> },
            { path: 'new', element: <MarginCreatePage /> },
            { path: ':id/edit', element: <MarginEditPage /> },
          ],
        },
        {
          path: 'quotation',
          children: [
            { element: <Navigate to="/dashboard/quotation/list" replace />, index: true },
            { path: 'list', element: <QuotationListPage /> },
            { path: ':id', element: <QuotationDetailsPage /> },
            { path: ':id/edit', element: <QuotationEditPage /> },
            { path: 'new', element: <QuotationCreatePage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace />, index: true },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
