import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

// DASHBOARD: USER
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));

// DASHBOARD: COUNTRY
export const CountryListPage = Loadable(lazy(() => import('../pages/dashboard/CountryListPage')));
export const CountryCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/CountryCreatePage'))
);
export const CountryEditPage = Loadable(
  lazy(() => import('../pages/dashboard/CountryEditPage'))
);

// DASHBOARD: PORT
export const PortListPage = Loadable(lazy(() => import('../pages/dashboard/PortListPage')));
export const PortCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/PortCreatePage'))
);
export const PortEditPage = Loadable(
  lazy(() => import('../pages/dashboard/PortEditPage'))
);

// DASHBOARD: COMPANY
export const CompanyListPage = Loadable(lazy(() => import('../pages/dashboard/CompanyListPage')));
export const CompanyCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/CompanyCreatePage'))
);
export const CompanyEditPage = Loadable(
  lazy(() => import('../pages/dashboard/CompanyEditPage'))
);

// DASHBOARD: CUSTOMER
export const CustomerListPage = Loadable(lazy(() => import('../pages/dashboard/CustomerListPage')));
export const CustomerCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/CustomerCreatePage'))
);
export const CustomerEditPage = Loadable(
  lazy(() => import('../pages/dashboard/CustomerEditPage'))
);

// DASHBOARD: SEA FREIGHT
export const SeaFreightListPage = Loadable(lazy(() => import('../pages/dashboard/SeaFreightListPage')));
export const SeaFreightCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/SeaFreightCreatePage'))
);
export const SeaFreightEditPage = Loadable(
  lazy(() => import('../pages/dashboard/SeaFreightEditPage'))
);

// DASHBOARD: MARGIN
export const MarginListPage = Loadable(lazy(() => import('../pages/dashboard/MarginListPage')));
export const MarginCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/MarginCreatePage'))
);
export const MarginEditPage = Loadable(
  lazy(() => import('../pages/dashboard/MarginEditPage'))
);

// DASHBOARD: QUOTATION
export const QuotationListPage = Loadable(lazy(() => import('../pages/dashboard/QuotationListPage')));
export const QuotationDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationDetailsPage'))
);
export const QuotationCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationCreatePage'))
);
export const QuotationEditPage = Loadable(lazy(() => import('../pages/dashboard/QuotationEditPage')));


export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
