// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  country: icon('ic_country'),
  port: icon('ic_port'),
  company: icon('ic_company'),
  customer: icon('ic_customers'),
  seaFreight: icon('ic_seaFreight'),
  margin: icon('ic_margin'),
  quotation: icon('ic_quotation'),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // COUNTRY
      {
        title: 'country',
        path: PATH_DASHBOARD.country.list,
        icon: ICONS.country,
      },
      // PORT
      {
        title: 'port',
        path: PATH_DASHBOARD.port.list,
        icon: ICONS.port,
      },
      // COMPANY
      {
        title: 'company',
        path: PATH_DASHBOARD.company.list,
        icon: ICONS.company,
      },
      // CUSTOMER
      {
        title: 'customer',
        path: PATH_DASHBOARD.customer.list,
        icon: ICONS.customer,
      },
      // SEA FREIGHT
      {
        title: 'seaFreight',
        path: PATH_DASHBOARD.seaFreight.list,
        icon: ICONS.seaFreight,
      },
      // MARGIN
      {
        title: 'margin',
        path: PATH_DASHBOARD.margin.list,
        icon: ICONS.margin,
      },
      // QUOTATION
      {
        title: 'quotation',
        path: PATH_DASHBOARD.quotation.root,
        icon: ICONS.quotation,
      },
    ],
  },
];

export default navConfig;
