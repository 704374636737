import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  success: null,
  ports: [],
  port: null
};

const slice = createSlice({
  name: 'port',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS SUCCESS
    hasSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET PORTS
    getPortsSuccess(state, action) {
      state.isLoading = false;
      state.ports = action.payload;
    },

    // GET PORT
    getPortSuccess(state, action) {
      state.isLoading = false;
      state.port = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPorts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/ports/getAllPorts');
      dispatch(slice.actions.getPortsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPort(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/ports/getOnePort/${id}`);
      console.log(response)
      dispatch(slice.actions.getPortSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  }
}
