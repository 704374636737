import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  success: null,
  margins: [],
  margin: null
};

const slice = createSlice({
  name: 'margin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS SUCCESS
    hasSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET SEA FREIGHTS
    getMarginsSuccess(state, action) {
      state.isLoading = false;
      state.margins = action.payload;
    },

    // GET SEA FREIGHT
    getMarginSuccess(state, action) {
      state.isLoading = false;
      state.margin = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMargins() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/margins/getAllMargins');
      dispatch(slice.actions.getMarginsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMargin(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/margins/getOneMargin/${id}`);
      dispatch(slice.actions.getMarginSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  }
}
